//--------------------------------------------------------------------------------------
// Prepare the configuration for the package
//--------------------------------------------------------------------------------------

import { mergeObject } from "df-helpers/dist/merge-object/merge-object";
import { be_app_info } from "./be-shared/be-app-info";
import { be_app_info as be_app_info_in_lib } from "df-frontend-02/dist/apps/shared/app-shared/be-app-info";

//------ register app pages --------

export function initBeAppInfo() {
	if (be_app_info.LANG_SET) be_app_info_in_lib.LANG_SET = be_app_info.LANG_SET;

	mergeObject(be_app_info_in_lib, be_app_info);
}

initBeAppInfo();
