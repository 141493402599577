import { addPage } from "df-frontend-02/dist/sys/modules/page-loader/add-get-page";
import { addDataGridDef } from "df-frontend-02/dist/sys/pages/data-grid/10.2-get-add-data-grid-defs";
/**
 * --------- important -----------
 * Don't import more modules, this is called by startup code in the application
 */

//register default grid definitions
export function registerAppPages() {
	//The following is machine generated code.
	//[CODE-GEN-PAGE]
	addPage("my-page", () => import("./1-pages/my-page/my-page"));
	//[CODE-GEN-GRID]
}
