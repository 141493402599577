import { DelayExec } from "df-helpers/dist/delay-exec/delay-exec";
import { useStaticMemo } from "df-react-model/dist/react-model-ui/hooks/use-static-memo";
import { ReactModel } from "df-react-model/dist/react-model/react-model";
import { useEffect } from "react";
import QRCode from "qrcode.react";
import { keyLockr_generateKeyPairs } from "./keylockr-helper";
import { api_keyLockrLogin_POST } from "src/be-shared/app-cdkapi-exports";
import { cfg_keylockr } from "./cfg-keylockr";
import { saveAuthToken } from "df-frontend-02/dist/sys/page-parts/login/api/get-set-auth-token";
import { encode } from "@msgpack/msgpack";

export function HLoginWithKeyLockr(props: {
	qr_height?: number;
	timeout_sec?: number;
	onSuccess: () => void;
}) {
	const qr_height = props.qr_height || 80;
	const timeout_sec = props.timeout_sec || 60;

	type TModel = {
		qr_code: string;
		state: "init" | "wait" | "expired";
		time_left: number;
		// key_pairs: TKeyLockr_KeyPairs;
	};
	const [model, key_pairs] = useStaticMemo(() => {
		const _key_pairs = keyLockr_generateKeyPairs();
		const _model = new ReactModel<TModel>({
			model: {
				qr_code: "",
				state: "init",
				time_left: 0,
			},
		});
		return [_model, _key_pairs];
	});
	model.useModel();
	const state = model.s.state;
	const delay_exec = new DelayExec(timeout_sec * 1000, () => {});

	useEffect(() => {
		startSSOLogin();
		return () => {
			delay_exec.cancel();
		};
	}, []);

	async function startSSOLogin() {
		model.setProperty({ state: "init" });

		const handshake_data = {
			name: cfg_keylockr.name,
			encrypt_pk: Buffer.from(key_pairs.encrypt_pk).toString("base64"),
			sign_pk: Buffer.from(key_pairs.sign_pk).toString("base64"),
		};

		//get token, tmp_id, and then load login status
		const result = await api_keyLockrLogin_POST({
			body: {
				cmd: "1-create-login-key",
				handshake_data: handshake_data,
			},
		});

		const c = result.data.data.create;

		if (!c) {
			throw Error("Failed to generate login QR code.");
		}

		const qr_code = cfg_keylockr.sso_link
			.replace("{svc_id}", cfg_keylockr.service_id)
			.replace("{tmp_id}", c.tmp_id)
			.replace("{token}", c.token);

		model.setProperty({ qr_code: qr_code, state: "wait" });

		checkLogin(c.tmp_id);
	}

	//check login
	function checkLogin(tmp_id: string) {
		delay_exec.setMonitor(time_left => {
			api_keyLockrLogin_POST({ body: { cmd: "2-check-login", tmp_id: tmp_id } }).then(
				result => {
					const login = result.data.data.login || {};
					if (login.token) {
						model.setProperty({ state: "expired" });
						saveAuthToken(login.token);
						props.onSuccess();
					}
				},
			);

			if (time_left) model.setProperty({ time_left });
			else {
				model.setProperty({ state: "expired" });
			}
		}, 1000);
		delay_exec.restart();
	}

	return (
		<table
			style={{ cursor: state === "expired" ? "pointer" : "default" }}
			onClick={() => {
				if (state === "expired") startSSOLogin();
			}}
		>
			<tbody>
				<tr>
					<td style={{ height: qr_height }}>
						{state === "init" && (
							<span
								style={{
									width: qr_height,
									height: qr_height,
									display: "inline-block",
									border: "1px solid silver",
								}}
							>
								<HMarkdown>{cfg_keylockr.prompt_md.prepare}</HMarkdown>
							</span>
						)}
						{state === "wait" && <QRCode value={model.s.qr_code} size={qr_height} />}
						{state === "expired" && (
							<QRCode
								style={{ opacity: 0.3 }}
								value={model.s.qr_code}
								size={qr_height}
							/>
						)}
					</td>
					<td className="ps-3">
						<span>
							{state === "init" && (
								<HMarkdown>cfg_keylockr.prompt_md.prepare</HMarkdown>
							)}
							{state === "wait" && (
								<span>
									<HMarkdown>{cfg_keylockr.prompt_md.scan}</HMarkdown>
									<span style={{ color: "gray" }}>
										<HMarkdown>
											{cfg_keylockr.prompt_md.timeout.replace(
												"{sec}",
												Math.round(model.s.time_left / 1000).toString(),
											)}
										</HMarkdown>
									</span>
								</span>
							)}
							{state === "expired" && (
								<span style={{ color: "gray" }}>
									<HMarkdown>{cfg_keylockr.prompt_md.expired}</HMarkdown>
								</span>
							)}
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	);
}
