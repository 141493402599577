//--------------------------------------------------------------------------------------
// Call initPackage first, which will configure the library
// initialization
//--------------------------------------------------------------------------------------
import "df-helpers/dist/array-ext/array-ext";

//Uncomment this line to use static translation file, no loading from server
// import "./init-static-translation";

import "./init-be-app-info";
import "./init-events";
import { registerAppPages } from "./register-app-pages";
import "./keylockr/login";

//--------------------------------------------------------------------------------------
// Start the page
// Import the following after initPackage() is called.
//--------------------------------------------------------------------------------------
import { frontend_framework } from "df-frontend-02/dist/index-internal";
import ReactDOM from "react-dom/client";

registerAppPages();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(frontend_framework);

//uncomment this line to create your own 404 page
// import { init404Page } from "./init-404-page";
// init404Page();
