//Machine generated file. Do not modify. Run the command "npm run create-api-exports" to create it
//This file is for the client side to call the web APIs

 
import { AxiosResponse } from "axios";
    

//--- get-sum | GET ---
declare global {
	type TInput_getSum_GET = TGenericRequest_Base & {
		a: string;
		b: string;
	};
	
	
	type TOutput_getSum_GET = TGenericResponse & {
		data: {
			sum: string;
		};
	};
}
export async function api_getSum_GET(params:TCallAPIParams<TInput_getSum_GET>):Promise<AxiosResponse<TOutput_getSum_GET>>{
    return callWebAPI("get","get-sum", params);
}

//--- keyLockr-login | POST ---
declare global {
	export type TKeyLockr_prelimHandShake_Copy = {
		encrypt_pk: string; // base64
		sign_pk: string; //base64
		name: string;
	};
	
	type TCreateSsoLink = {
		cmd: "1-create-login-key";
		handshake_data: TKeyLockr_prelimHandShake_Copy;
	};
	
	type TCheckLogin = {
		cmd: "2-check-login";
		tmp_id: string;
	};
	
	type TInput_keyLockrLogin_POST = TGenericRequest_Base & (TCreateSsoLink | TCheckLogin);
	
	
	type TOutput_keyLockrLogin_POST = TGenericResponse & {
		data: {
			msg: string;
			code?: string;
			create?: {
				tmp_id: string;
				token: string;
			};
	
			login?: {
				/** login token. */
				token?: string;
				refresh_token?: string;
				/** How many existing login sessions are removed */
				logout_sessions?: number;
				/** Login user id */
				user_id?: string;
			};
		};
	};
}
export async function api_keyLockrLogin_POST(params:TCallAPIParams<TInput_keyLockrLogin_POST>):Promise<AxiosResponse<TOutput_keyLockrLogin_POST>>{
    return callWebAPI("post","keyLockr-login", params);
}

//--- sso_push_auth | POST ---
declare global {
	type TKeyLockr_PushData_Copy = {
		tmp_id: string;
		sso_client_id: string;
		user_id: string;
		user_nickname: string;
		user_gender: string;
		user_alias: string;
		data_token: string;
		data_filekey_sso: string;
		data_plain: string | undefined;
		data_encrypted: string | undefined;
	};
	
	type TInput_ssoPushAuth_POST = TGenericRequest_Base & TKeyLockr_PushData_Copy;
	
	
	type TOutput_ssoPushAuth_POST = TGenericResponse;
}
export async function api_ssoPushAuth_POST(params:TCallAPIParams<TInput_ssoPushAuth_POST>):Promise<AxiosResponse<TOutput_ssoPushAuth_POST>>{
    return callWebAPI("post","sso_push_auth", params);
}