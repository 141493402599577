// Frontend configuration file
// Do not import. This is loaded at startup

import {
	sys_menu_language,
	sys_menu_table_mgr,
	sys_menu_user_mgr,
	sys_menu_user_profile,
} from "df-frontend-02/dist/apps/app/app-predef-menu";

export function getAppInfo(): Partial<IAppInfo> {
	const ret: Partial<IAppInfo> = {
		app_name: "Wabao",

		//browser title
		doc_title_template: "{0} -Wabao",
		footer_line: "© Wabao All rights reserved.",

		path_after_logout: "/",
		path_after_login: "/",
		path_to_login: "/",
		path_to_site_admin: "/admin",

		/** do not load app-info.js from server db */
		merge_with_db: true,

		navbar: {
			color: "black",

			/** left menu will completely replace the menu in the package */
			left_menu: [
				// {
				// 	title: trans("MENU01", "Home"),
				// 	roles: ["everyone"],
				// 	href: "/",
				// },
				{
					title: "表格",
					roles: ["sadmin", "admin", "tag_director"],
					sub_menus: [
						{
							title: "Tag 結構表",
							roles: ["everyone"],
							href: "data-grid/resource-tag_architecture",
						},
						{
							title: "挖寶表",
							roles: ["everyone"],
							href: "data-grid/resource-wabao",
						},
						{
							title: "頻道帳密",
							roles: ["everyone"],
							href: "data-grid/resource-channel_credentials_2",
						},
					],
				},
				{
					title: "版權組",
					roles: ["admin", "copyright_team"],
					sub_menus: [
						{
							title: "某某某",
							roles: ["copyright_team"],
							href: "sample_page",
						},
					],
				},
				//[CODE-GEN-MENU]
				{
					title: "Help",
					roles: ["admin"],
					sub_menus: [
						{
							title: "Instella",
							roles: ["everyone"],
							href: "help/cms",
						},
						{
							title: "Component samples",
							roles: ["everyone"],
							href: "help/component-samples",
						},
						{
							title: "Apis",
							roles: ["everyone"],
							href: "help/apis",
						},
						{
							title: "Cypress test",
							roles: ["everyone"],
							href: "help/cypress-test",
						},
						{
							title: "-",
							roles: ["everyone"],
						},
						{
							title: "Sample table - Score",
							roles: ["everyone"],
							href: "/data-grid/resource-score",
						},
						{
							title: "Sample page - My page",
							roles: ["everyone"],
							href: "/my-page",
						},
					],
				},
				{
					title: sys_menu_user_mgr,
				},
				{
					title: sys_menu_table_mgr,
				},
			],
			right_menu: [{ title: sys_menu_language }, { title: sys_menu_user_profile }],
			sticky: false,
		},

		// apiPort: number, //api port number

		// urlAfterLogin: string;
		// urlHome: string;

		// beEndPoint: () => "http://localhost:3333",

		// show_breadcrumb: true,
	};
	return ret;
}
