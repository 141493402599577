import nacl from "tweetnacl";

export type TKeyLockr_KeyPairs = {
	encrypt_pk: Uint8Array;
	encrypt_sk: Uint8Array; // sk means secret key, an alias for private key.
	sign_pk: Uint8Array;
	sign_sk: Uint8Array;
};

export function keyLockr_generateKeyPairs(): TKeyLockr_KeyPairs {
	const { publicKey: encrypt_pk, secretKey: encrypt_sk } = nacl.box.keyPair();
	const { publicKey: sign_pk, secretKey: sign_sk } = nacl.sign.keyPair();
	return { encrypt_sk: encrypt_sk, encrypt_pk: encrypt_pk, sign_sk: sign_sk, sign_pk: sign_pk };
}
